import {
  ADD_DEPENDANTS,
  ADD_DEPENDANTS_FAILED,
  DELETE_DEPENDANT,
  GET_DEPENDANTS,
  RESET_DEPENDANTS_STATUS,
  UPDATE_DEPENDANT,
  UPDATE_DEPENDANT_DOCUMENT,
  ADD_DEPENDANT_DOCUMENT
} from './actions';

const initialState = {
  dependants: null,
  isLoading: false,
  error: null,
  status: 'idle'
};

export default (state = initialState, action) => {
  const { type, dependants, dependant, error } = action;
  switch (type) {
    case GET_DEPENDANTS: {
      return { ...state, dependants };
    }
    case UPDATE_DEPENDANT: {
      return {
        ...state,
        dependants: state.dependants.map((item) =>
          item.id === dependant.id ? dependant : item
        )
      };
    }
    case ADD_DEPENDANTS: {
      return {
        ...state,
        status: 'success',
        dependants: [...state.dependants, dependant]
      };
    }
    case ADD_DEPENDANTS_FAILED: {
      return { ...state, status: 'failed', error };
    }
    case DELETE_DEPENDANT: {
      const { id } = action.payload;
      return {
        ...state,
        dependants: state.dependants.filter((item) => item.id !== id)
      };
    }
    case RESET_DEPENDANTS_STATUS:
      return { ...state, status: 'idle' };

    case UPDATE_DEPENDANT_DOCUMENT: {
      const { documents } = action;

      return {
        ...state,
        dependants: state.dependants.map((item) => {
          if (item?.documents?.id === documents?.id) {
            item.documents = documents;
          }
          return item;
        })
      };
    }
    case ADD_DEPENDANT_DOCUMENT: {
      const { documents, id } = action;

      return {
        ...state,
        dependants: state.dependants.map((item) => {
          if (item?.id === id) {
            item.documents = documents;
          }
          return item;
        })
      };
    }
    default:
      return state;
  }
};
