import { ClickAwayListener } from '@mui/base';
import HelpIcon from '@mui/icons-material/Help';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {
  Box,
  Button,
  Divider,
  Fab,
  Fade,
  Grid,
  Link,
  Popper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MainDialog from 'components/MainDialog';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import FeedbackForm from './feeback-form';

export default function CustomerFeedback() {
  const theme = useTheme();
  const { isLoggedIn } = useAuth();
  const matchesXS = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const matchXXS = useMediaQuery('(max-width:380px)');

  const [openFeedback, setOpenFeedback] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  const btnStyles = { minWidth: 'max-content', justifyContent: 'flex-start' };

  const toggleHelpBtn = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleHelpBtn();
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const toggleFeedback = () => {
    setOpenFeedback((prev) => !prev);
    toggleHelpBtn();
  };

  const toggleContacts = () => {
    setOpenContact((prev) => !prev);
  };

  return (
    <Box>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Box sx={{ position: 'relative' }}>
          <Fab
            component="div"
            onClick={handleClick}
            size={matchesXS ? 'small' : 'medium'}
            variant="extended"
            color="primary"
            sx={{
              position: 'fixed',
              right: 25,
              bottom: 0,
              padding: '5px 36px',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              border: 0,
              fontSize: '1rem',
              zIndex: theme.zIndex.speedDial,
              boxShadow: theme.customShadows.secondary,
              backgroundColor: theme.palette.orange.dark,
              color: '#fff',
              height: 'max-content',
              minWidth: 'max-content'
            }}
          >
            Need Help?
          </Fab>
          <Popper
            id={open ? 'customer-support-options' : undefined}
            open={open}
            anchorEl={anchorEl}
            transition
            sx={{ zIndex: 9999 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box
                  sx={{
                    bgcolor:
                      isLoggedIn || matchXXS
                        ? 'background.paper'
                        : 'transparent',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    p: 4
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<HelpIcon />}
                    sx={btnStyles}
                    component={Link}
                    href="/faqs"
                    target="_blank"
                  >
                    Frequently Asked Questions
                  </Button>
                  <Button
                    variant="contained"
                    component={Link}
                    href="https://survey.actserv.co.ke/index.php/survey/index/sid/362415/newtest/Y/lang/en"
                    target="_blank"
                    rel="noreferrer"
                    startIcon={<SupportAgentIcon />}
                    sx={btnStyles}
                  >
                    Customer Support
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<RateReviewIcon />}
                    sx={btnStyles}
                    onClick={toggleFeedback}
                  >
                    Feedback
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<SettingsPhoneIcon />}
                    sx={btnStyles}
                    onClick={toggleContacts}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
      <FeedbackForm
        openFeedback={openFeedback}
        toggleFeedback={toggleFeedback}
      />
      <MainDialog
        width="sm"
        open={openContact}
        handleModalOpen={toggleContacts}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" color="primary" align="center">
              Contact Us
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1">
              For any questions contact us on:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography color="secondary" align="left">
                  1. +254718936701<span style={{ color: '#000' }}>, or</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="secondary" align="left">
                  2. +254745354575
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainDialog>
    </Box>
  );
}
