import useRoles from 'hooks/useRoles';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AdminGuard({ children }) {
  const { role } = useRoles();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (role && role !== 'admin')
      navigate('/unauthorized', { state: { from: location } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return children;
}
