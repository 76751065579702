import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { startCase } from 'lodash';
import { buildImagePath } from 'utils/helpers';

export const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('serviceToken') || null;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'Current User',
    'User Group Settings',
    'User Documents',
    'All Users'
  ],
  endpoints: (builder) => ({
    addUserDocuments: builder.mutation({
      query: ({ documents, docsUrl }) => ({
        url: docsUrl,
        method: 'POST',
        body: documents
      }),
      invalidatesTags: ['User documents', 'Current User']
    }),
    fetchUserDocuments: builder.query({
      query: ({ role, id }) => {
        if (role === 'admin' || role === 'groupAdmin') {
          return `/documents/admin/user/${id}/`;
        }
        return '/documents/user/';
      },
      providesTags: ['User documents'],
      transformResponse: (data) => {
        const titles = ['birth_certificate', 'national_id', 'kra_certificate'];
        const documents = {};
        if (data.length) {
          titles.forEach((title) => {
            if (data[0][title]) {
              documents[title] = {
                image: buildImagePath(data[0][title]),
                created_at: data[0].created_at,
                updated_at: data[0].updated_at,
                status: data[0].status[title].status,
                remarks: data[0].status[title].comment,
                title: startCase(title),
                id: data[0].id
              };
            }
          });
        }
        return documents;
      }
    }),
    verifyDocuments: builder.mutation({
      query: (document) => ({
        url: `/documents/admin/${document.id}/`,
        method: 'PATCH',
        body: document
      }),
      invalidatesTags: ['User documents']
    }),
    updateDocument: builder.mutation({
      query: ({ documentId, document }) => ({
        url: `/documents/user/${documentId}/`,
        method: 'PATCH',
        body: document
      }),
      invalidatesTags: ['User documents']
    }),
    fetchGroupMembers: builder.query({
      query: ({ role, groupId }) =>
        role === 'admin'
          ? `/groups/admin/${groupId}`
          : '/groups/group_members/',
      providesTags: ['Group Members']
    }),
    fetchGroups: builder.query({ query: () => '/groups/admin' }),
    switchMembers: builder.mutation({
      query: (details) => ({
        url: '/users/all/switch-group/',
        method: 'PATCH',
        body: details
      }),
      invalidatesTags: ['Group Members']
    }),
    registerGroup: builder.mutation({
      query: (name) => ({
        url: '/groups/',
        method: 'POST',
        body: { name }
      })
    }),
    addReps: builder.mutation({
      query: ({ groupId, reps: body }) => ({
        url: `/groups/add_rep/${groupId}/`,
        method: 'POST',
        body
      })
    }),
    addGroupAdmin: builder.mutation({
      query: (data) => ({
        url: '/groups/group_admin/',
        method: 'POST',
        body: data
      })
    }),
    fetchIndividualTrasactions: builder.query({
      query: () => '/transactions/',
      providesTags: ['Individual Transactions'],
      transformResponse: (data) =>
        data ? [...data].sort((a, b) => b.id - a.id) : []
    }),
    fetchGroupTrasactions: builder.query({
      query: () => '/bulk_transactions/',
      providesTags: ['Group Transactions'],
      transformResponse: (data) =>
        data ? [...data].sort((a, b) => b.id - a.id) : []
    }),
    updateIndividualTransaction: builder.mutation({
      query: (data) => ({
        url: `/transactions/${data.transactionId}/`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Individual Transactions']
    }),
    updateGroupTransaction: builder.mutation({
      query: (data) => ({
        url: `/bulk_transactions/${data.transactionId}/`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Group Transactions']
    }),
    addGroupTransaction: builder.mutation({
      query: (data) => ({
        url: `/bulk_transactions/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Group Transactions']
    }),
    addIndividualTransaction: builder.mutation({
      query: (data) => ({
        url: '/transactions/user/policy/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Individual Transactions']
    }),
    payPremium: builder.mutation({
      query: (data) => ({
        url: 'premium_payment/submit/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Premiums', 'Single Policy', 'Single Policy Admin']
    }),
    fetchPremiums: builder.query({
      query: () => '/premiums/',
      transformResponse: (data) =>
        [...data].sort((a, b) => b.created_at.localeCompare(a.created_at)) ||
        [],
      providesTags: ['Premiums']
    }),
    searchPremiums: builder.query({
      query: (searchValue) => `/premiums/?${searchValue}`,
      invalidatesTags: ['Premiums']
    }),
    resendPhoneVerify: builder.mutation({
      query: () => ({
        url: 'users/send-verify/',
        method: 'POST'
      })
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: '/users/details/',
        method: 'PUT',
        body: data
      })
    }),
    fetchPolicy: builder.query({
      query: (policyId) => `/policies/user/${policyId}`,
      providesTags: ['Single Policy']
    }),
    fetchPolicyforAdmin: builder.query({
      query: (policyId) => `/policies/admin/${policyId}`,
      providesTags: ['Single Policy Admin']
    }),
    recordPolicyTransaction: builder.mutation({
      query: (data) => ({
        url: '/transactions/user/policy/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Current User', 'Single Policy', 'Single Policy Admin']
    }),
    currentUser: builder.query({
      query: () => '/users/details/',
      providesTags: ['Current User']
    }),
    fetchOptions: builder.query({ query: () => 'benefits/all/' }),
    selectOption: builder.mutation({
      query: (optionId) => ({
        url: `benefits/${optionId}/`,
        method: 'PUT'
      }),
      invalidatesTags: ['Current User']
    }),
    fetchPersonalPolicies: builder.query({
      query: () => '/policies/user/',
      providesTags: ['User Policies'],
      transformResponse: (data) =>
        [...data].sort((a, b) => b.created_at.localeCompare(a.created_at)) || []
    }),
    addPolicy: builder.mutation({
      query: (data) => ({
        url: '/policies/user/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['User Policies']
    }),
    fetchGroupPolicies: builder.query({
      query: ({ role, group }) => {
        if (role === 'admin') return `/policies/admin/groups/${group}/`;
        if (['groupRep', 'groupAdmin'].includes(role))
          return '/groups/group_policies/';
        return '';
      },
      transformResponse: (data) =>
        (Array.isArray(data) ? [...data] : []).sort((a, b) =>
          b.created_at.localeCompare(a.created_at)
        ) || [],
      providesTags: ['Group Policies']
    }),
    fetchAllPolicies: builder.query({
      query: () => 'policies/admin/',
      transformResponse: (data) =>
        [...data].sort((a, b) => b.created_at.localeCompare(a.created_at)) || []
    }),
    updatePolicyUser: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/policies/user/${id}/`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['Single Policy'],
      refetchQueries: ['fetchPolicy']
    }),
    updatePolicyAdmin: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/policies/admin/${id}/`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['Single Policy Admin'],
      refetchQueries: ['fetchPolicyforAdmin']
    }),
    addCommissionRate: builder.mutation({
      query: (data) => ({
        url: '/commission_rates/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Commission Rates'],
      refetchQueries: ['filterCommissionRates']
    }),
    fetchAllUsers: builder.query({
      query: () => 'users/all/',
      transformResponse: (data) =>
        [...data].sort((a, b) => a.first_name.localeCompare(b.first_name)) ||
        [],
      providesTags: ['All Users']
    }),
    fetchCommissionRates: builder.query({
      query: (choice) => `commission_rates?choice_rate=${choice}`,
      transformResponse: (data) =>
        [...data].sort((a, b) => a.created_at.localeCompare(b.created_at)),
      providesTags: ['Commission Rates']
    }),
    fetchCommissionBaseRate: builder.query({
      query: () => 'base_rate',
      providesTags: ['Base Rate']
    }),
    fetchCommissions: builder.query({
      query: () => 'commissions',
      transformResponse: (data) =>
        [...data].sort((a, b) => a.created_at.localeCompare(b.created_at)),
      providesTags: ['All Commissions']
    }),
    filterCommissions: builder.query({
      query: (params) => `commissions${params}`,
      transformResponse: (data) =>
        [...data].sort((a, b) => a.created_at.localeCompare(b.created_at))
    }),
    payCommissions: builder.mutation({
      query: (data) => ({
        url: '/commissions/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['All Commissions'],
      refetchQueries: ['All Commissions']
    }),
    fetchGroupSettings: builder.query({
      query: (groupId) => `/groups/group_settings/${groupId}/`,
      providesTags: ['Admin Group Settings']
    }),
    addGroupSettings: builder.mutation({
      query: (data) => ({
        url: `/groups/group_settings/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Admin Group Settings', 'Group Data', 'Current User']
    }),
    updateGroupSettings: builder.mutation({
      query: (group) => ({
        url: `/groups/group_settings/${group.id}/`,
        method: 'PATCH',
        body: group.values
      }),
      invalidatesTags: ['Group Data', 'Current User'] // group data comes from the user details when it's the group admin
    }),
    fetchGroupSettingsUser: builder.query({
      query: (groupId) => `/groups/group_settings/fetch/${groupId}/`,
      providesTags: ['User Group Settings']
    }),
    fetchGroupById: builder.query({
      query: ({ role, id }) => (role === 'admin' ? `/groups/admin/${id}/` : ''),
      providesTags: ['Group Data']
    }),
    addBaseRate: builder.mutation({
      query: (payload) => ({
        url: 'base_rate/',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Base Rate'],
      refetchQueries: ['fetchCommissionBaseRate']
    }),
    updateBaseRate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/base_rate/${id}/`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['Base Rate'],
      refetchQueries: ['fetchCommissionBaseRate']
    }),
    updateCommissionRate: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/commission_rates/${id}/`,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['Commission Rates'],
      refetchQueries: ['filterCommissionRates']
    })
  })
});

export const {
  useAddUserDocumentsMutation,
  useFetchUserDocumentsQuery,
  useVerifyDocumentsMutation,
  useFetchGroupMembersQuery,
  useFetchGroupsQuery,
  useSwitchMembersMutation,
  useUpdateDocumentMutation,
  useAddGroupAdminMutation,
  useAddRepsMutation,
  useRegisterGroupMutation,
  useFetchGroupTrasactionsQuery,
  useFetchIndividualTrasactionsQuery,
  useAddGroupTransactionMutation,
  useAddIndividualTransactionMutation,
  useUpdateGroupTransactionMutation,
  useUpdateIndividualTransactionMutation,
  usePayPremiumMutation,
  useFetchPremiumsQuery,
  useLazySearchPremiumsQuery,
  useResendPhoneVerifyMutation,
  useUpdateUserMutation,
  useFetchPolicyQuery,
  useRecordPolicyTransactionMutation,
  useCurrentUserQuery,
  useFetchOptionsQuery,
  useSelectOptionMutation,
  useAddPolicyMutation,
  useFetchPersonalPoliciesQuery,
  useLazyFetchGroupPoliciesQuery,
  useFetchGroupPoliciesQuery,
  useFetchAllPoliciesQuery,
  useFetchPolicyforAdminQuery,
  useUpdatePolicyUserMutation,
  useUpdatePolicyAdminMutation,
  useAddCommissionRateMutation,
  useLazyFetchAllUsersQuery,
  useFetchAllUsersQuery,
  useFetchCommissionRatesQuery,
  useFetchCommissionBaseRateQuery,
  useFetchCommissionsQuery,
  useLazyFilterCommissionsQuery,
  usePayCommissionsMutation,
  useLazyFetchGroupSettingsQuery,
  useAddGroupSettingsMutation,
  useLazyFetchGroupSettingsUserQuery,
  useFetchGroupByIdQuery,
  useUpdateGroupSettingsMutation,
  useUpdateBaseRateMutation,
  useUpdateCommissionRateMutation,
  useAddBaseRateMutation
} = rootApi;
