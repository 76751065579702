import { Collapse, Alert, Button, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MainDialog from 'components/MainDialog';
import { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import OtpForm from './OtpForm';

export default function VerifyPhone() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { user } = useAuth();
  useEffect(() => {}, [user]);
  const handleOpen = () => {
    setOpen((prevState) => !prevState);
  };
  return (
    <>
      <MainDialog open={open} handleModalOpen={handleOpen} width="sm">
        <OtpForm handleModalOpen={handleOpen} />
      </MainDialog>
      {user?.mobile_verified === false && (
        <Collapse in>
          <Alert
            sx={{
              marginBottom: 1,
              backgroundColor: theme.palette.orange.light
            }}
            variant="standard"
            severity="error"
            action={
              <>
                <Button onClick={handleOpen} variant="contained" size="small">
                  Verify Phone
                </Button>
                <IconButton aria-label="close" color="inherit" size="small">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            }
          >
            Your mobile number is not verified
          </Alert>
        </Collapse>
      )}
    </>
  );
}
