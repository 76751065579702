import {
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  styled,
  Typography
} from '@mui/material';
import MainCard from 'components/cards/MainCard';
import BrooksLogo from 'assets/images/brooks.png';

// eslint-disable-next-line no-unused-vars
const P = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '1.8em'
}));
export default function PrivacyPolicy() {
  return (
    <MainCard sx={{ my: 3 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={7}>
          <Grid
            container
            spacing={3}
            sx={{
              color: '#000',
              width: '100%',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '26px'
            }}
          >
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="center">
                <img
                  src={BrooksLogo}
                  alt="Brooks logo"
                  style={{ padding: 5 }}
                  width="128px"
                  height="64px"
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                align="center"
                sx={{
                  textTransform: 'uppercase',
                  // borderBottom: '2px solid #000',
                  // width: 'max-content',
                  // maxWidth: '100%',
                  fontSize: '1.8rem'
                }}
                gutterBottom
              >
                liwaza privacy policy
              </Typography>
              {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                1. Introduction
              </Typography>
              <P>
                We are committed to safeguarding and preserving the privacy of
                our users.
                <br />
                <br />
                This privacy policy outlines how we collect, use, and protect
                personal information that you provide to us, or that we collect
                from you while you use our application. We respect your right to
                privacy and are committed to maintaining it. We only collect,
                store and process your personal information according to the
                relevant laws and regulations. By accessing, browsing or
                otherwise using the application you confirm that you have read
                and understood this Privacy Policy. Ensure you have read it
                carefully.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                2. Definitions
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>“Brooks,” “We,” “our,” “ours,” and “us,”</strong>{' '}
                  means Brooks Insurance Agencies Ltd and includes its
                  successors in title and assigns, its affiliates and/or its
                  strategic business units as may be specified from time to
                  time.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>“Personal data” or “personal information”</strong>{' '}
                  means: Information about you or information that identifies
                  you as a unique individual, such as your name and surname
                  combined with your physical address, contact details ,
                  identity card or passport number.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>“Processing”</strong> means handling, collecting,
                  using, altering, merging, linking, organizing, disseminating,
                  storing, protecting, retrieving, disclosing, erasing,
                  archiving, destroying, or disposing of your personal
                  information.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>“Sensitive personal information”</strong> includes
                  data revealing your race, health status, ethnic social origin,
                  conscience, belief, genetic data, biometric data, property
                  details, marital status, family details including details of
                  your children, parents, spouse or spouses, sex or sexual
                  orientation.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>“You”</strong> means: Customer, which includes
                  personal representatives and assigns doing business with us
                  and includes (where appropriate) any person you authorize to
                  give us instructions, the person who uses any of our products
                  and services or accesses our websites. “Customer” shall
                  include both the masculine and the feminine gender as well as
                  a legal person.
                  <br />
                  Any visitor that is a person (including partners or any third
                  parties) who gains access to any of our premises. Any
                  supplier/ service provider who has been contracted by us
                  and/or signed a service level agreement with us.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  The word <strong>“includes”</strong> means that what follows
                  is not necessarily exhaustive and therefore the examples given
                  are not the only things or situations included in the meaning
                  or explanation of that text.
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                3. Information We Collect
              </Typography>
              <P>We may collect the following personal information:</P>
              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>Individual details: </strong>
                  Name, address ,email and telephone details, gender, marital
                  status, family details, date and place of birth, relationship
                  to the policyholder insured, beneficiary or claimant.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>Identification details: </strong>
                  Identification numbers issued by government bodies or agencies
                  (e.g. ID number).
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <b>Financial information: </b> Mobile money number, mobile
                  money statements, bank account number and account details, and
                  other financial information.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>Online data: </strong>
                  When you use our products and services through our website,
                  mobile applications such as cookies, login data, IP address
                  (your computer’s internet address), browser type and version,
                  ISP or operating system, domain name, access time, page views,
                  location data, how you frequently use our online insurance,
                  banking and other services, our mobile applications or visit
                  our website; or
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <strong>Profile data: </strong>
                  Such as your username and password, your interests,
                  preferences, feedback and survey responses.
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                4. Where We Collect Personal Information
              </Typography>
              <P>We collect Personal Data from various sources, including:</P>
              <List sx={{ listStyleType: 'lower-alpha', pl: 2 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  Directly from individuals and their family members, online or
                  by telephone, or in written correspondence;
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  From individuals’ employers;
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>Claim forms.</ListItem>
              </List>

              <P>
                Once you register on our platform, you will no longer be
                anonymous to us and will provide us with personal information.{' '}
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                5. Purpose For Data Processing And The Legal Basis
              </Typography>
              <P>
                The primary purpose for collecting and processing your personal
                data is to perform statutory and contractual activities related
                to management of the insurance product you have with us. We will
                also process your data in connection with other tasks as
                required by law and statutory regulations. In addition to these,
                personal data may be used in service and product development.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                6. Storage And Protection Of Personal Data
              </Typography>
              <P>
                We maintain appropriate technical and organizational safeguards
                against unauthorized processing of personal data and against
                accidental loss, destruction or damage.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                7. Use Of Personal Data
              </Typography>
              <P>
                We use your personal information to manage your policy, process
                payments, and manage claims. We may also use your information to
                provide you with marketing materials or to improve our services.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                8. How We Share Your Information
              </Typography>
              <P>
                We may share your personal information with third-party service
                providers, such as payment processors or claims management
                companies, who help us manage your policy and process your
                payments. We may also share your information with as required by
                law and statutory regulations.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                9. Consent
              </Typography>
              <P>
                In order to facilitate the provision of our services, we rely on
                the data subject’s consent to process personal sensitive
                information, such as age and marital status . This consent
                allows us to share the information with Insurers and other
                Intermediaries that may need to process the information in order
                to undertake their role in the insurance industry.
              </P>
              <P>
                The affected individual’s consent to the processing of personal
                information is a necessary condition for us to be able to
                provide the services requested. When you are providing us with
                information about a person other than yourself, you agree to
                notify them of our use of their Personal Data and to obtain such
                consent for us.
              </P>
              <P>
                Individuals may withdraw their consent to such processing at any
                time. However, doing so may prevent us from continuing to
                provide the services. In addition, if an individual withdraws
                consent to an Insurer’s processing of their Personal Data, it
                may not be possible for the insurance cover to continue.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                10. Cookies
              </Typography>
              <P>
                We may use cookies and similar technologies on our websites ,
                systems, apps and in our emails. Cookies are text files that
                gather small amounts of information, which your computer or
                mobile device stores when you visit a website or use an app.
                When you return to the website or app, or visit websites and
                apps that use the same cookies, they recognize these cookies and
                your device.
              </P>
              <P>
                Cookies make it easier for us to give you a better experience
                online. You can stop your browser from accepting cookies, but if
                you do, some parts of our website or online services may not
                work. We recommend that you allow cookies
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                11. Marketing
              </Typography>
              <P>
                If you give us permission, we may use your personal or other
                information to tell you about products, services and special
                offers from us or other companies that may be of interest to
                you. We will do this by post, email or text message (SMS),
                whatsapp and social media platforms. If later you decide that
                you would not want us to do this, please contact us at{' '}
                <Link
                  href="mailto:dpo@brooks.co.ke"
                  target="_blank"
                  rel="noreferrer"
                  color="secondary"
                  sx={{ fontWeight: 'bold', textDecoration: 'none' }}
                >
                  dpo@brooks.co.ke
                </Link>{' '}
                and we will update your preferences.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                12. Transfer Across Borders
              </Typography>
              <P>
                Your data is primarily stored in our data server located in
                Kenya. Some data is stored on cloud, and accessed in other
                countries. We are committed to ensure an appropriate level of
                protection by the recipient of the data when we transmit your
                data outside Kenya.
              </P>
              <P>
                If we transfer Personal Data to other countries outside Kenya,
                there are legal grounds justifying such transfer, such as
                individuals’ consent, or other legal grounds permitted by law.
              </P>
              <P>
                By dealing with us and submitting your personal information, you
                agree to the transfer, storage, and/or processing of your
                personal information outside of Kenya.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                13. Your Rights As A Data Subject
              </Typography>
              <P>You have the right to:</P>
              <List sx={{ listStyleType: 'lower-alpha', pl: 2 }}>
                <ListItem sx={{ display: 'list-item' }}>
                  Access a copy of your Personal Data processed.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  Correct, delete or verify your Personal Data that we process
                  that you have submitted to us.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  Request erasure of your Personal Data where there is no good
                  reason for us to continue to process it. Please note, that we
                  reserve the right to decline this request for specific legal
                  or regulatory obligations.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  Object to the processing of all or part of your personal data.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  Request the transfer of your Personal Data to you or a third
                  party. We will provide to you, or a third party you have
                  chosen, your Personal Data in a commonly used,
                  machine-readable format. Please note that this right only
                  applies to information which you initially provided consent
                  for us to use or where we used the information to perform a
                  contract with you.
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  Withdraw your consent to the processing of your Personal Data.
                  We may, in certain circumstances continue to process your if
                  there is a legitimate reason to do so.
                </ListItem>
              </List>
              <P>
                So as to exercise your rights as provided above, we may request
                specific information from you to help us confirm your identity.
                This is a security measure to ensure that Personal Data is not
                disclosed to any person who has no right to receive it. We may
                also contact you to ask you for further information in relation
                to your request.
              </P>
              <List sx={{ listStyleType: `'>'` }}>
                <P>Requests, questions or concerns and should be:</P>
                <ListItem sx={{ display: 'list-item' }}>
                  Made at our head office :- <br />{' '}
                  <strong>
                    Brooks Insurance Agencies Limited, 26th Floor,
                    <br /> Old Mutual Tower, Upper Hill Road, <br /> P.O Box
                    15850-00100 Nairobi Kenya <br />
                    Tel:{' '}
                    <Link
                      color="secondary"
                      href="tel:0111023600"
                      sx={{ textDecoration: 'none' }}
                    >
                      0111023600
                    </Link>
                  </strong>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  Sent via email to:{' '}
                  <Link
                    href="mailto:dpo@brooks.co.ke"
                    target="_blank"
                    rel="noreferrer"
                    color="secondary"
                    sx={{ fontWeight: 'bold', textDecoration: 'none' }}
                  >
                    dpo@brooks.co.ke
                  </Link>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                14. Data Security
              </Typography>
              <P>
                Personal information is safeguarded against unauthorized access
                , use, disclosure, alteration, damage or loss.
                <br />
                We take reasonable steps to ensure the integrity, accuracy and
                confidentiality of personal information held by it. We have
                implemented adequate security measures such as access control,
                encryption, physical security, and other best practices, to
                protect personal data.We will, on an ongoing basis, continue to
                review our security controls and related processes to ensure
                that your personal information is secure.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                15. Retention And Storing Your Personal Data
              </Typography>
              <P>
                We will retain your personal information for as long as you have
                your account with us, or as long as it is required for us to be
                able to provide the relevant services to you. We may retain your
                personal data and information as may be required by law and
                maintain specific records management and retention policies and
                procedures.If the data is not required anymore for statutory or
                contractual obligations, it will be deleted.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                16. Updates To This Privacy Policy
              </Typography>
              <P>
                We reserve the right to modify or update this privacy policy
                from time to time to reflect changes in our data processing
                practices or legal requirements. We shall notify you of any
                material changes to this policy.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                17. Limitation Of Liability
              </Typography>
              <P>
                We recognize our responsibility in relation to the collection,
                holding, processing or use of personal information. The
                provision of your personal information is voluntary. You may
                choose not to provide us with the requested data, but failure to
                do so may inhibit our ability to provide services to you.
                <br />
                We are not responsible for, give no warranties, nor make any
                representations in respect to the privacy policies or practices
                of linked or any third party websites.
              </P>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                18. Contact Us
              </Typography>
              <P>
                If you have any questions about our privacy practices, or want
                to exercise any of your rights, contact our Data Protection
                Officer:
              </P>
              <List sx={{ fontWeight: 'bold' }}>
                <ListItem sx={{ p: 0 }}>Data Protection Officer,</ListItem>
                <ListItem sx={{ p: 0 }}>Brooks Insurance Agency,</ListItem>
                <ListItem sx={{ p: 0 }}>
                  26th Floor, Old Mutual Tower, Upper Hill Road,{' '}
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  P.O Box 15850-00100 Nairobi. Kenya
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  Tel:&nbsp;
                  <Link
                    color="secondary"
                    href="tel:0111023600"
                    sx={{ textDecoration: 'none' }}
                  >
                    0111023600
                  </Link>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  Email:&nbsp;
                  <Link
                    href="mailto:dpo@brooks.co.ke"
                    target="_blank"
                    rel="noreferrer"
                    color="secondary"
                    sx={{ textDecoration: 'none' }}
                  >
                    dpo@brooks.co.ke
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
}
