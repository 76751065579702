import Loader from 'components/Loader';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authHeader from 'services/auth-header';
import accountReducer from 'store/accountReducer';
import { LOGIN, LOGIN_FAILED, LOGOUT, RESET_CURRENT_USER } from 'store/actions';
import { rootApi } from 'store/store';
import axios from 'utils/axios';
import { logEvent } from 'utils/mixpanelClient';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  error: null,
  resetPasswordErrors: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const navigate = useNavigate();
  const storeDispatch = useDispatch();

  const init = async () => {
    try {
      const serviceToken = window.localStorage.getItem('serviceToken');
      if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken);
        const response = await axios.get('/users/details/', {
          headers: {
            Authorization: `Bearer ${serviceToken}`
          }
        });
        const user = response.data;
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });
      } else {
        dispatch({
          type: LOGOUT
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: LOGOUT
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const login = useCallback(async (phone, password) => {
    const result = {};
    try {
      const response = await axios.post('/users/login/', {
        mobile: phone,
        password
      });
      const user = response.data;
      setSession(user.token);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user
        }
      });
      logEvent('Login', {
        user: { role: user?.user_role, email: user?.email }
      });
      init();
    } catch (e) {
      dispatch({
        type: LOGIN_FAILED,
        error: Object.values(e)
      });
      result.authError = Object.values(e);
    }
    return result;
  }, []);

  const register = async (values) => {
    const result = { status: 'idle', message: '' };
    try {
      const response = await axios.post('/users/register/', values);
      const user = response.data;
      window.localStorage.setItem('token', user.token);
      result.status = 'success';
      logEvent('Sign Up', {
        user: { role: user?.user_role, email: user?.email }
      });
    } catch (e) {
      result.status = 'failed';
      result.message = Object.entries(e);
    }

    return result;
  };

  const logout = useCallback(() => {
    localStorage.removeItem('new_policy_details');
    setSession(null);
    dispatch({ type: LOGOUT });
    storeDispatch({ type: RESET_CURRENT_USER }); // resets the user stored in redux store
    storeDispatch(rootApi.util.resetApiState());
    navigate('login', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // from profile
  const changePassword = async (values) => {
    await axios.put(`/users/change-password/`, values, {
      headers: {
        Authorization: authHeader()
      }
    });
  };

  // auth reset password
  // step 1
  const forgotPassword = async (values) => {
    let response = { status: 'idle', message: '' };
    try {
      const res = await axios.post(`/users/reset-password/`, values);
      response = { status: 'success', message: res.statusText };
    } catch (err) {
      response = { status: 'failed', message: Object.values(err) };
    }
    return response;
  };

  // step 2
  const validatePasswordToken = async (values) => {
    let response = { status: 'idle', message: '' };
    try {
      const res = await axios.post(`/users/validate-token/`, values);
      response = { status: 'success', message: res.statusText };
    } catch (err) {
      response = { status: 'failed', message: Object.values(err) };
    }
    return response;
  };

  // step 3
  const resetPassword = async (values) => {
    let response = { status: 'idle', message: '' };
    try {
      const res = await axios.post(`/users/reset-confirm/`, values);
      response = { status: 'success', message: res.statusText };
    } catch (err) {
      response = { status: 'failed', message: Object.values(err) };
    }
    return response;
  };
  const updateUser = () =>
    dispatch({
      type: LOGIN,
      payload: {
        user: { ...state.user, mobile_verified: true }
      }
    });
  const values = useMemo(
    () => ({
      ...state,
      login,
      logout,
      register,
      changePassword,
      resetPassword,
      forgotPassword,
      validatePasswordToken,
      updateUser
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login, logout, state]
  );

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  return <JWTContext.Provider value={values}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default JWTContext;
