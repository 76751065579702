import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDependants } from 'services/dependants.service';
import beneficiariesServices from 'services/beneficiaries.service';
import useRoles from './useRoles';

export default function useRequired() {
  const [hasDependants, setHasDependants] = useState(true);
  const [hasBeneficiaries, setHasBeneficiaries] = useState(true);
  const dispatch = useDispatch();
  const { role } = useRoles();
  const isMember = role && ['member', 'groupRep'].includes(role);
  const { dependants } = useSelector((state) => state.dependants);
  const { beneficiaries } = useSelector((state) => state.beneficiaries);
  useEffect(() => {
    if (isMember) {
      dispatch(fetchDependants());
      dispatch(beneficiariesServices.getBeneficiaries());
    }
  }, [isMember, dispatch]);
  useEffect(() => {
    if (Array.isArray(dependants) && dependants.length === 0) {
      setHasDependants(false);
    } else if (Array.isArray(dependants) && dependants.length > 0) {
      setHasDependants(true);
    }
    if (Array.isArray(beneficiaries) && beneficiaries.length === 0) {
      setHasBeneficiaries(false);
    } else if (Array.isArray(beneficiaries) && beneficiaries.length > 0) {
      setHasBeneficiaries(true);
    }
  }, [dependants, beneficiaries]);
  return { hasBeneficiaries, hasDependants };
}
