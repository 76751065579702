import axios from 'utils/axios';
import authHeader from './auth-header';

const verifyPhoneNumber = async (otp, handleSuccess, handleError) => {
  try {
    await axios.post(
      '/users/mobile-verify/',
      { token: otp },
      {
        headers: {
          Authorization: authHeader()
        }
      }
    );
    handleSuccess();
  } catch (err) {
    handleError(err);
  }
};

export default verifyPhoneNumber;
