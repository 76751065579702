import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const initMixpanel = () => {
  if (process.env.NODE_ENV !== 'production') return;
  if (!MIXPANEL_TOKEN) {
    console.warn('Mixpanel token is missing! Check your .env file.');
    return;
  }

  mixpanel.init(MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true
  });
};

export default function MixPanel() {
  useEffect(() => {
    initMixpanel();
  }, []);

  return null;
}

export function logEvent(type, data) {
  if (process.env.NODE_ENV !== 'production') return;
  mixpanel.track(type, data);
}
